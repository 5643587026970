import devLogger from 'react-utils/devLogger';

function deprecated(propType, message, defaultValue) {
  const deprecatedPropType = (props, propName, componentName, location, ...rest) => {
    const propValue = props[propName];
    const output = typeof message === 'function' ? message(propName, propValue, props) : message;

    if (output && propValue !== defaultValue) {
      devLogger.warn({
        message: output,
        key: null
      });
    }

    return propType(props, propName, componentName, location, ...rest);
  };

  if (propType.__INTROSPECTION__) {
    deprecatedPropType.__INTROSPECTION__ = Object.assign({}, propType.__INTROSPECTION__, {
      deprecated: true
    });
  }

  return deprecatedPropType;
}

export default deprecated;