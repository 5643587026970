import { debugLog } from './debug';
import { findPlaceholderImages } from './placeholders';
const HS_SCRIPT_LOADER_ID = 'hs-script-loader';
const HS_SCRIPT_LOADER_PATH_PREFIX = '/hs/scriptloader/';
const VIDEO_EMBED_SRC_PATTERN = /video-embed\/ex\/loader(-qa)?.js/;
export function getSelfScriptEl() {
  const scripts = Array.from(document.querySelectorAll('script[src]'));
  return scripts.find(scriptEl => VIDEO_EMBED_SRC_PATTERN.test(scriptEl.src) || scriptEl.src.endsWith('/video-embed/static-1.3081/bundles/loader.js'));
}
export function parsePortalIdFromScriptLoader() {
  const scriptLoaderEl = document.getElementById(HS_SCRIPT_LOADER_ID);

  if (scriptLoaderEl && scriptLoaderEl.src && scriptLoaderEl.src.includes(HS_SCRIPT_LOADER_PATH_PREFIX)) {
    const a = document.createElement('a');
    a.href = scriptLoaderEl.src;
    const portalIdStr = a.pathname.replace(HS_SCRIPT_LOADER_PATH_PREFIX, '').replace('.js', '');
    const portalId = Number(portalIdStr);

    if (portalId > 0) {
      return portalId;
    }
  }

  return null;
}
export function getSelfScriptSrc() {
  const scriptEl = getSelfScriptEl();
  return scriptEl ? scriptEl.src : null;
}
export const hasRendered = el => el.dataset.hsvStatus === 'rendered';
export function getEmbedPlaceholderEl(id, onlyUnused = false) {
  if (onlyUnused) {
    return document.querySelector(`[data-hsv-embed-id="${id}"]:not([data-hsv-status])`);
  }

  return document.querySelector(`[data-hsv-embed-id="${id}"]`);
}
export function hasDocLoaded() {
  return document.readyState === 'interactive' || document.readyState === 'complete';
}
const SCRIPT_LOAD_TIMEOUT_MS = 10000;
export function loadScript(src) {
  const script = document.createElement('script');
  return new Promise((resolve, reject) => {
    let loaded = false;

    script.onload = () => {
      loaded = true;
      resolve();
    };

    script.onerror = reject;
    script.src = src;
    document.getElementsByTagName('head')[0].appendChild(script);
    setTimeout(() => {
      if (!loaded) {
        console.log(`Loading script ${src} timed out`);
        reject();
      }
    }, SCRIPT_LOAD_TIMEOUT_MS);
  });
}
export function setStyles(el, styleObj = {}) {
  Object.assign(el.style, styleObj);
}
export function hasParentWithClass(el, className) {
  while (el && el.parentElement) {
    // SVGElement className is not a simple string
    if (typeof el.parentElement.className === 'string' && el.parentElement.className.includes(className)) {
      return true;
    }

    el = el.parentElement;
  }

  return false;
}
export function setupFormSubmittedListener() {
  const handleFormSubmitted = ({
    id
  }) => {
    const inlineMessageEl = document.querySelector(`.hs-form-${id}.submitted-message`);

    if (inlineMessageEl) {
      const placeholders = findPlaceholderImages(inlineMessageEl);
      debugLog(`Found ${placeholders.length} players in form thank you message`);
      placeholders.forEach(({
        el,
        options
      }) => {
        options.renderContext = 'form-inline-message';
        window.hsVideoApi.renderPlayer(el, options);
      });
    }
  };

  window.addEventListener('message', event => {
    if (event.data && event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
      debugLog('Form submitted', event.data);
      setTimeout(() => handleFormSubmitted(event.data));
    }
  });
}