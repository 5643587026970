import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/noAuthApiClient';
import { hubletApi } from 'hub-http/middlewares/core';
export const publicTrackingApi = hubletApi('public', 'hubapi', window.hsVideoHubletOverride);
export function fetchVideo(id, params = {}) {
  const query = Object.assign({
    portalId: PortalIdParser.get()
  }, params);
  return http.get(`video/v1/public/${id}/player`, {
    query,
    api: publicTrackingApi,
    withCredentials: false
  });
}
export function trackPlayEvent(crmObjectId, sessionId, contactUtk, extra = {}) {
  const query = {
    portalId: PortalIdParser.get()
  };
  const data = Object.assign({}, extra, {
    crmObjectId,
    sessionId,
    contactUtk
  });
  return http.post('media-bridge/public/v1/media/played', {
    data,
    query,
    api: publicTrackingApi,
    withCredentials: false
  });
}
export function trackSecondsViewed(crmObjectId, sessionId, contactUtk, extra = {}) {
  const query = {
    portalId: PortalIdParser.get()
  };

  if (extra.endState) {
    query.endState = true;
  }

  delete extra.endState;
  const data = Object.assign({}, extra, {
    crmObjectId,
    sessionId,
    contactUtk
  });
  return http.post('media-bridge/public/v1/media/seconds-viewed', {
    data,
    query,
    api: publicTrackingApi,
    withCredentials: false
  });
}