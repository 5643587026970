module.exports = {
  "videoLoaded": {
    "name": "Video loaded",
    "class": "usage",
    "properties": {
      "autoplay": "boolean",
      "loop": "boolean",
      "muted": "boolean",
      "hiddenControls": "boolean",
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "pageDomain": {
        "type": "string",
        "isOptional": true
      },
      "pageUrl": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetType": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetPosition": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "viaSharePage": {
        "type": "boolean",
        "isOptional": true
      },
      "hasUtk": {
        "type": "boolean",
        "isOptional": true
      },
      "isEmbeddable": "boolean",
      "hasPrimaryPlaybackId": "boolean"
    },
    "namespace": "video-player",
    "version": "1"
  },
  "playerInteraction": {
    "name": "Player interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "pageDomain": {
        "type": "string",
        "isOptional": true
      },
      "pageUrl": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetType": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetPosition": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "viaSharePage": {
        "type": "boolean",
        "isOptional": true
      },
      "hasUtk": {
        "type": "boolean",
        "isOptional": true
      },
      "isEmbeddable": "boolean",
      "hasPrimaryPlaybackId": "boolean"
    },
    "namespace": "video-player",
    "version": "1"
  },
  "videoAttentionSpan": {
    "name": "Video attention span",
    "class": "usage",
    "properties": {
      "viewDuration": "number",
      "videoDuration": "number",
      "completed": "boolean",
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "pageDomain": {
        "type": "string",
        "isOptional": true
      },
      "pageUrl": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetType": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetPosition": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "viaSharePage": {
        "type": "boolean",
        "isOptional": true
      },
      "hasUtk": {
        "type": "boolean",
        "isOptional": true
      },
      "isEmbeddable": "boolean",
      "hasPrimaryPlaybackId": "boolean"
    },
    "namespace": "video-player",
    "version": "1"
  }
};