import { FETCH_VIDEO_FAILED, FETCH_VIDEO_SUCCESS } from '../actions/ActionTypes';
const InitialVideoState = {
  videoObject: null,
  videoFetchError: null
};
export default function videoReducer(state = InitialVideoState, action) {
  switch (action.type) {
    case FETCH_VIDEO_SUCCESS:
      return Object.assign({}, state, {
        videoObject: action.video
      });

    case FETCH_VIDEO_FAILED:
      return Object.assign({}, state, {
        videoFetchError: action.error
      });

    default:
      return state;
  }
}