import * as envUtils from './env';
import { PlayerTypes } from '../types';
import { getGlobalOptions, getHsVarsRendererContext, isCmsEditorPreview, parseContentIdFromBody } from './cms';
import { pick } from './object';
import { PLAYER_IFRAME_PASSTHRU_PARAMS } from '../constants';
import { isDebugEnabled } from './debug';
import { reportMessage } from './errors';
export function parseQueryParams(queryString = window.location.search) {
  const params = new window.URLSearchParams(queryString);
  const queryObj = {};
  params.forEach((val, key) => {
    queryObj[key] = val;
  });
  return queryObj;
}
export function stringifyQueryParams(queryObj) {
  const truthyQueryObj = {};
  Object.keys(queryObj).forEach(key => {
    if (queryObj[key] || typeof queryObj[key] === 'number') {
      truthyQueryObj[key] = queryObj[key];
    }
  });
  return new window.URLSearchParams(truthyQueryObj).toString();
}

function queryToString(params) {
  return typeof params === 'object' && Object.keys(params).length ? `?${stringifyQueryParams(params)}` : '';
}

export function buildIframeQueryParams(options) {
  const hsVars = getHsVarsRendererContext();
  const pageId = hsVars && typeof hsVars.page_id === 'number' ? hsVars.page_id : parseContentIdFromBody();
  const iframeParams = Object.assign({}, pick(options, PLAYER_IFRAME_PASSTHRU_PARAMS), {
    parentOrigin: window.location.origin,
    pageId
  });

  if (!iframeParams.parentOrigin) {
    reportMessage(`window.location is missing - ${document.documentURI}`);
  }

  if (isDebugEnabled()) {
    iframeParams.debug = true;
  }

  if (isCmsEditorPreview()) {
    iframeParams.playerType = PlayerTypes.EDITOR_PREVIEW;
  }

  const globalOptions = getGlobalOptions();

  if (globalOptions.hidePosterImage) {
    iframeParams.hidePosterImage = true;
  }

  const isFullWidthHeight = options.height === '100%' && (options.width === '100%' || options.full_width);

  if (isFullWidthHeight) {
    iframeParams.objectFit = 'cover';
  }

  const queryParams = parseQueryParams();

  if (queryParams._hsVideoForm) {
    options.conversionAsset = {
      type: 'FORM',
      position: 'PRE',
      id: queryParams._hsVideoForm
    };
  } else if (queryParams._hsVideoCta) {
    options.conversionAsset = {
      type: 'CTA',
      position: 'PRE',
      id: queryParams._hsVideoCta
    };
  }

  if (options.conversionAsset && typeof options.conversionAsset === 'object' && options.conversionAsset.type) {
    iframeParams.conversionAsset = JSON.stringify(options.conversionAsset);
  }

  if (iframeParams.play_button_color) {
    iframeParams.play_button_color = iframeParams.play_button_color.replace('#', '');
  }

  return iframeParams;
}
export function getEmbedIframeUrl(portalId, videoId, params = {}, embedId) {
  let path = 'video';

  if (portalId && videoId) {
    path = envUtils.isAuthedAppPreview() ? `video/${portalId}/id/${videoId}` : `v/${portalId}/id/${videoId}`;
  }

  const hash = embedId ? `#hsvid=${embedId}` : '';
  return `${envUtils.getEmbedIframeDomain()}/${path}${queryToString(params)}${hash}`;
}