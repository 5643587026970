module.exports = {
  "/id/:videoId": {
    "success": {
      "player loaded": [
        "PLAYER_LOADED"
      ],
      "video not found": [
        "VIDEO_NOT_FOUND"
      ]
    },
    "error": [
      "PLAYER_ERROR"
    ]
  }
};