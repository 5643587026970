const ONE_DAY = 24 * 60 * 60 * 1000;
export const UTK_COOKIE_NAME = 'hubspotutk';
export function getCookie(name) {
  let cookieValue = null;

  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
      cookie = cookie.trim();

      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = cookie.substring(name.length + 1);
      }
    });
  }

  return cookieValue;
}
export function setCookie(name, value, expiresInMillis = ONE_DAY) {
  const d = new Date();
  d.setTime(d.getTime() + expiresInMillis);
  const expiryDate = d.toUTCString();
  document.cookie = `${name}=${value};expires=${expiryDate};path=/;SameSite=None;Secure`;
}