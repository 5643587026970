import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import Raven from 'Raven';
import { parse, stringify } from 'hub-http/helpers/params';
import { na1 as DEFAULT_HUBLET } from 'hubspot-url-utils/hublets';
import { ConversionAssetPosition, ConversionAssetTypes } from 'video-embed/constants';
import { PlayerTypes } from 'video-embed/types';
import { parseQueryParams } from 'video-embed/utils/url';
const VIDEO_ID_PATH_REGEX = /(v|video)\/(\d+)\/id\/(\d+)/i;
const VIDEO_EMBED_PLAYER_ID_REGEX = /^#hsvid=([\w-]+)/i;
const BOOL_PARAMS = ['autoplay', 'loop', 'muted', 'hiddenControls', 'hidePosterImage'];

function parseBoolParam(valueStr) {
  return valueStr === 'true' || valueStr === '1';
}

function parseConversionAsset(conversionAssetJson) {
  let conversionAsset;

  try {
    conversionAsset = JSON.parse(conversionAssetJson);
  } catch (e) {
    console.warn('Could not parse conversionAsset param JSON', conversionAssetJson, e);
    return null;
  }

  if (!(conversionAsset.id && conversionAsset.type && conversionAsset.position)) {
    return null;
  }

  conversionAsset.type = conversionAsset.type.toUpperCase();

  if (!Object.values(ConversionAssetTypes).includes(conversionAsset.type)) {
    console.warn(`Unknown conversionAsset type: ${conversionAsset.type}`);
    return null;
  }

  conversionAsset.position = conversionAsset.position.toUpperCase();

  if (!Object.values(ConversionAssetPosition).includes(conversionAsset.position)) {
    console.warn(`Unknown conversionAsset position: ${conversionAsset.position}`);
    return null;
  }

  if (conversionAsset.position === ConversionAssetPosition.CUSTOM) {
    const delaySeconds = conversionAsset.delay_seconds || conversionAsset.overlay_delay_seconds || conversionAsset.overlayDelaySeconds;

    if (Number(delaySeconds) >= 0) {
      conversionAsset.delaySeconds = Number(delaySeconds);
      delete conversionAsset.overlay_delay_seconds;
    }
  }

  if (parseBoolParam(conversionAsset.prevent_skip) || conversionAsset.prevent_skip === true) {
    delete conversionAsset.prevent_skip;
    conversionAsset.preventSkip = true;
  }

  return conversionAsset;
}

export function parsePlayerUrlParams(path = window.location.pathname, queryString = window.location.search, hash = window.location.hash) {
  const params = parseQueryParams(queryString);
  params.portalId = PortalIdParser.get();

  if (params.id) {
    params.id = parseInt(params.id, 10);
    Raven.captureMessage(`Unexpected player path: ${path}`);
  } else if (path) {
    const match = path.match(VIDEO_ID_PATH_REGEX);

    if (match && match.length === 4) {
      params.id = parseInt(match[3], 10);
    }
  }

  if (hash) {
    const match = hash.match(VIDEO_EMBED_PLAYER_ID_REGEX);

    if (match && match[1]) {
      params.embedId = match[1];
    }
  }

  if (params.hidden_controls) {
    params.hiddenControls = params.hidden_controls;
    delete params.hidden_controls;
  }

  BOOL_PARAMS.forEach(param => {
    if (params[param]) {
      params[param] = parseBoolParam(params[param]);
    }
  });

  if (params.richTextPreview) {
    params.richTextPreview = parseBoolParam(params.richTextPreview);
    params.playerType = PlayerTypes.RICH_TEXT_PREVIEW;
  } else if (params.playerType === PlayerTypes.RICH_TEXT_PREVIEW) {
    params.richTextPreview = true;
  }

  if (params.play_button_color) {
    params.playButtonColor = params.play_button_color;
    delete params.play_button_color;
  }

  if (params.playButtonColor && params.playButtonColor.startsWith('#')) {
    params.playButtonColor = params.playButtonColor.replace('#', '');
  } // video widget defaults to white making triangle svg invisible, so ignore


  if (params.playButtonColor === 'ffffff') {
    delete params.playButtonColor;
  }

  if (params.play_button_shape) {
    params.playButtonShape = params.play_button_shape;
    delete params.play_button_shape;
  }

  if (params.conversion_asset) {
    params.conversionAsset = params.conversion_asset;
    delete params.conversion_asset;
  }

  if (params.conversionAsset) {
    params.conversionAsset = parseConversionAsset(params.conversionAsset);

    if (!params.conversionAsset) {
      delete params.conversionAsset;
    }
  }

  return params;
}
export function getCtaFallbackImageUrl(guid) {
  const domain = enviro.isQa() ? 'hubspotqa.com' : 'hubspot.com';
  let fallbackImgOrigin = `//no-cache.${domain}`;

  if (enviro.getHublet() !== DEFAULT_HUBLET) {
    fallbackImgOrigin = enviro.isQa() ? '//hubspot-no-cache-eu1-qa.s3.amazonaws.com' : '//hubspot-no-cache-eu1-prod.s3.amazonaws.com';
  }

  return `${fallbackImgOrigin}/cta/default/${PortalIdParser.get()}/${guid}.png`;
}
export function getCtaRedirectUrl(guid) {
  const domain = enviro.isQa() ? 'hubspotqa.com' : 'hubspot.com';
  let redirectOrigin = `//cta-redirect.${domain}`;

  if (enviro.getHublet() !== DEFAULT_HUBLET) {
    redirectOrigin = enviro.isQa() ? '//hubspot-cta-redirect-eu1-qa.s3.amazonaws.com' : '//hubspot-cta-redirect-eu1-prod.s3.amazonaws.com';
  }

  return `${redirectOrigin}/cta/redirect/${PortalIdParser.get()}/${guid}`;
}
export const parseQueryObject = location => location.search ? parse(location.search.substr(1)) : null;
export const mergeQueryParams = (location, queryParamUpdate = {}) => {
  let queryObj = parseQueryObject(location);
  queryObj = Object.assign({}, queryObj, {}, queryParamUpdate);
  location.search = `?${stringify(queryObj)}`;
  return location;
};