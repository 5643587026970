import PropTypes from 'prop-types';
import createChainablePropType from './createChainablePropType';
let propType;
export function getIconNamePropType() {
  if (!propType) {
    let iconNames = []; // Optimization: omit this require in production builds (which skip proptype validation)
    // @ts-expect-error TODO: add webpack-env dep where we pull process types

    if (process.env.NODE_ENV !== 'production') {
      // @ts-expect-error require
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const iconNamesModule = require('icons/iconNames');

      iconNames = iconNamesModule.default || iconNamesModule;
    }

    propType = PropTypes.oneOf(iconNames);
  }

  return createChainablePropType(propType, 'iconName');
}