import { getSelfScriptSrc } from './dom';
import { getHublet, isCmsQa } from './cms'; // cannot depend on enviro or other basic HeadJS utils here

export const ENV_KEY = 'VIDEO_PLAYER_ENV';
const QA_LIVEPATH_SUFFIX = '-qa.js';
const QA_APP_DOMAINS = ['.hubspotqa.com', '.hubspotvideoqa.com', '.hsappstatic.net'];
let localStorageErrorThrown = false;
export function tryLocalStorageGet(key, retryAfterError = true) {
  if (!retryAfterError && localStorageErrorThrown) {
    return null;
  }

  try {
    return localStorage.getItem(key);
  } catch (e) {
    localStorageErrorThrown = true;
    return null;
  }
}

const getWindowEnv = () => window[ENV_KEY];

export function isLocal() {
  if (getWindowEnv()) {
    return getWindowEnv() === 'local';
  }

  if (tryLocalStorageGet(ENV_KEY) === 'local') {
    return true;
  }

  return false;
}
export function isQa() {
  if (isCmsQa() || tryLocalStorageGet(ENV_KEY) === 'qa') {
    return true;
  }

  if (getWindowEnv()) {
    return getWindowEnv() === 'qa';
  }

  const embedScriptSrc = getSelfScriptSrc();
  return QA_APP_DOMAINS.some(domain => window.location.hostname.endsWith(domain)) || embedScriptSrc && embedScriptSrc.endsWith(QA_LIVEPATH_SUFFIX);
}
export function getEmbedIframeDomain() {
  if (isAuthedAppPreview()) {
    if (isLocal()) {
      return isQa() ? 'https://local.hubspotqa.com' : 'https://local.hubspot.com';
    }

    return window.location.origin;
  }

  const region = getHublet();
  const regionSuffix = region ? `-${region}` : '';

  if (isLocal()) {
    return isQa() ? `https://local${regionSuffix}.hubspotvideoqa.com` : `https://local${regionSuffix}.hubspotvideo.com`;
  }

  const appDomain = isQa() ? 'hubspotvideoqa.com' : 'hubspotvideo.com';
  return `https://play${regionSuffix}.${appDomain}`;
}
export function isJasmine() {
  return 'jasmineRequire' in window;
}
export function isAuthedAppPreview() {
  if (isJasmine()) {
    return false;
  }

  return window.location.origin.endsWith('.hubspot.com') || window.location.origin.endsWith('.hubspotqa.com');
}
export function isIframed() {
  return window.parent !== window;
}
export function isSelenium() {
  return Boolean(tryLocalStorageGet('selenium.disable.alarms'));
}