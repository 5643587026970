import Raven from 'Raven';
import { MessageTypes } from 'video-embed/constants';
import { debugLog } from 'video-embed/utils/debug';
import { trackPlayEvent, trackSecondsViewed } from './api';
const CHUNK_DURATION_SECONDS = 1;
const RETENTION_TRACKING_INTERVAL_SECONDS = 10; // only track `seconds-viewed` aka retention events for the first 1 hour, and disable loop mode

const RETENTION_MAX_SESSION_DURATION_SECONDS = 60 * 60;

const getVideoProperties = video => ({
  videoDuration: video.duration / 1000,
  videoId: video.videoId,
  videoTitle: video.title
});

export class RetentionReporter {
  constructor(video, {
    embedId,
    sessionId,
    utk,
    pageMeta,
    postMessageToParent
  }, tracker) {
    this.video = video;
    this.embedId = embedId;
    this.sessionId = sessionId;
    this.utk = utk;
    this.pageMeta = pageMeta;
    this.chunksViewed = new Map();
    this.chunksViewedSinceReport = new Map();
    this.tracker = tracker;
    this.trackingStartedAt = new Date().getTime();

    if (postMessageToParent) {
      this.postMessageToParent = postMessageToParent;
    } else {
      this.postMessageToParent = () => null;
    }
  }

  trackPlay() {
    const {
      sessionId,
      utk,
      video
    } = this;

    if (!utk || this.playEventPromise) {
      return this.playEventPromise || Promise.resolve();
    }

    const eventData = Object.assign({}, this.pageMeta, {
      crmObjectId: video.crmObjectId,
      sessionId,
      utk
    });
    this.playEventPromise = trackPlayEvent(video.crmObjectId, sessionId, utk, this.pageMeta).then(() => {
      if (this.tracker) {
        this.tracker.track('playerInteraction', {
          action: 'tracked-play'
        });
      }

      this.postMessageToParent(MessageTypes.TRACKED_PLAY, eventData);
    }).catch(err => {
      console.error(err);
      const errorData = {
        status: err.status,
        responseJSON: err.responseJSON,
        requestBody: eventData
      };
      Raven.captureMessage(`Failed to track play event - status: ${err.status}`, {
        extra: errorData,
        tags: {
          status: err.status
        }
      });
    });
    return this.playEventPromise;
  }

  trackNewChunksViewed(endState = false) {
    if (!this.utk || !this.chunksViewedSinceReport.size) {
      return;
    }

    const secondsToViews = Object.fromEntries(this.chunksViewedSinceReport.entries());
    const extra = Object.assign({}, this.pageMeta, {
      secondsToViews,
      endState
    });
    debugLog(`Tracking ${endState ? 'final' : 'interval'} seconds viewed`, secondsToViews);
    trackSecondsViewed(this.video.crmObjectId, this.sessionId, this.utk, extra).catch(err => {
      console.error(err);
      const errorData = {
        status: err.status,
        responseJSON: err.responseJSON,
        requestBody: {
          crmObjectId: this.video.crmObjectId,
          sessionId: this.sessionId,
          utk: this.utk,
          extra,
          endState
        },
        parentPageUrl: this.pageMeta.pageUrl
      };
      Raven.captureMessage(`Failed to track seconds viewed events - status: ${err.status}`, {
        extra: errorData,
        tags: {
          status: err.status
        }
      });
    });
    this.chunksViewedSinceReport.clear();
    this.lastRetentionReported = Date.now();
  }

  fillRemainingSecondsViewed() {
    const videoDurationSeconds = this.video.duration / 1000;
    const totalChunks = Math.floor(videoDurationSeconds / CHUNK_DURATION_SECONDS);

    if (this.chunksViewed.size < totalChunks) {
      debugLog(`${totalChunks - this.chunksViewed.size} remaining chunks to track for looped video`);

      for (let i = 0; i < videoDurationSeconds; i++) {
        if (!this.chunksViewed.get(i) && !this.chunksViewedSinceReport.get(i)) {
          this.chunksViewedSinceReport.set(i, 1);
        }
      }
    }
  }

  onChunkViewed(chunkSeconds) {
    this.chunksViewed.set(chunkSeconds, (this.chunksViewed.get(chunkSeconds) || 0) + 1);
    this.chunksViewedSinceReport.set(chunkSeconds, (this.chunksViewedSinceReport.get(chunkSeconds) || 0) + 1); // base reporting interval on first second viewed of initial play

    if (!this.lastRetentionReported) {
      this.lastRetentionReported = Date.now();
    }

    const secondsSinceLastReport = (Date.now() - this.lastRetentionReported) / 1000;

    if (secondsSinceLastReport >= RETENTION_TRACKING_INTERVAL_SECONDS) {
      this.trackNewChunksViewed();
    }
  }

  trackAttentionSpan(completed = false) {
    if (this.trackedCompletion) {
      this.trackNewChunksViewed();
      return;
    }

    const {
      embedId,
      video
    } = this;
    const viewDuration = this.chunksViewed.size * CHUNK_DURATION_SECONDS;
    debugLog(`Tracking final attention span for player ${embedId}`, {
      completed,
      viewDuration
    });

    if (this.tracker) {
      this.tracker.track('videoAttentionSpan', Object.assign({}, getVideoProperties(video), {
        completed,
        viewDuration
      }));
    }

    this.trackedCompletion = true;
  }

  getSessionDuration() {
    return (Date.now() - this.trackingStartedAt) / 1000;
  }

  hasViewedCompletely() {
    const videoDurationSeconds = this.video.duration / 1000;
    const totalChunks = Math.floor(videoDurationSeconds / CHUNK_DURATION_SECONDS);
    return this.chunksViewed.size >= totalChunks;
  }

  getTimeRangesDuration(timeRanges) {
    const durations = [];
    let i = 0;

    while (i < timeRanges.length) {
      durations.push(timeRanges.end(i) - timeRanges.start(i));
      i++;
    }

    return durations.sort()[durations.length - 1];
  }

}
export function setupRetentionTracking(video, player, {
  embedId,
  sessionId,
  utk,
  pageMeta,
  postMessageToParent
}, tracker) {
  const videoDurationSeconds = video.duration / 1000;
  const reporter = new RetentionReporter(video, {
    embedId,
    sessionId,
    utk,
    pageMeta,
    postMessageToParent
  }, tracker);
  debugLog(utk ? 'Setting up retention tracking for player' : 'Setting up only amplitude tracking for in-app preview', reporter);
  let lastChunkStarted = 0;

  const handleTimeUpdate = () => {
    // videojs off is broken, so no way to detach `timeupdate` listener https://github.com/videojs/video.js/issues/5648
    if (reporter.trackedCompletion) {
      return;
    }

    const currentChunk = Math.floor(player.currentTime() / CHUNK_DURATION_SECONDS);

    if (lastChunkStarted === currentChunk - 1) {
      reporter.onChunkViewed(lastChunkStarted);
    }

    lastChunkStarted = currentChunk;

    if (reporter.getSessionDuration() >= RETENTION_MAX_SESSION_DURATION_SECONDS) {
      debugLog(`Ending retention tracking due to session duration reaching ${reporter.getSessionDuration()}`);
      reporter.trackNewChunksViewed(false);
      reporter.trackAttentionSpan(false);

      if (player.loop()) {
        player.loop(false);
      }
    }

    if (player.loop()) {
      // looped videos don't fire ended event so "end state" needs to be approached differently
      const duration = reporter.getTimeRangesDuration(player.played());

      if (duration >= videoDurationSeconds) {
        debugLog(`Ending retention tracking - TimeRanges with duration ${duration} indicate complete view`);
        reporter.fillRemainingSecondsViewed();
        reporter.trackNewChunksViewed(true);
        reporter.trackAttentionSpan(true);
      }

      if (reporter.hasViewedCompletely()) {
        debugLog('Ending retention tracking for looped video watched completely', videoDurationSeconds, reporter.getSessionDuration());
        reporter.trackNewChunksViewed(true);
        reporter.trackAttentionSpan(true);
      }
    }
  };

  const handleEnded = e => {
    debugLog('Ended retention tracking due to ended event', e);
    reporter.trackNewChunksViewed(true);
    reporter.trackAttentionSpan(reporter.hasViewedCompletely());
  };

  const handleVisibilityStateChange = () => {
    if (reporter.trackedCompletion || !player.hasStarted()) {
      return;
    }

    if (document.visibilityState === 'hidden') {
      debugLog(`Ending retention tracking for player ${embedId} due to visibilityState change to: ${document.visibilityState}`);
      reporter.trackNewChunksViewed(true);
      reporter.trackAttentionSpan(false);
      document.removeEventListener('visibilitychange', handleVisibilityStateChange);
    }
  };

  player.one('playing', () => reporter.trackPlay());
  player.on('timeupdate', handleTimeUpdate);
  player.on('ended', handleEnded);
  document.addEventListener('visibilitychange', handleVisibilityStateChange);
  return reporter;
}