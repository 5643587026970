import { setStyles } from 'video-embed/utils/dom';
import { DEFAULT_PLAY_BUTTON_COLOR, PLAY_BUTTON_SVG, PlayButtonShapes } from '../constants';
export function setupPlayButton(player, params) {
  const playButtonEl = player.bigPlayButton.el();
  const playButtonShape = params.playButtonShape ? params.playButtonShape.toUpperCase() : PlayButtonShapes.CIRCLE;

  if (Object.values(PlayButtonShapes).includes(playButtonShape)) {
    playButtonEl.classList.add(`play-button-${playButtonShape.toLowerCase()}`);
  }

  let playButtonColor = params.playButtonColor || DEFAULT_PLAY_BUTTON_COLOR;

  if (!playButtonColor.startsWith('#')) {
    playButtonColor = `#${playButtonColor}`;
  }

  setStyles(playButtonEl, {
    backgroundColor: playButtonColor
  });
  playButtonEl.innerHTML = PLAY_BUTTON_SVG;
}