/* hs-eslint ignored failing-rules */

/* eslint-disable compat/compat */
'use es6';

import enviro from 'enviro';
import I18n from 'I18n';
import getLang from 'I18n/utils/getLang';
import userInfo from 'hub-http/userInfo';
import apiClient from 'hub-http/clients/apiClient';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { createClient } from 'usage-tracker-core';
import { debugKey, hstcKey } from 'usage-tracker-core/storageKeys';
import * as cookieHelper from './helpers/cookieHelper';
import sendBeacon from './helpers/sendBeacon';
import reportError from './helpers/reportError';
import * as tempStorage from './containers/tempStorage';
const hasBeaconSupport = navigator && typeof navigator.sendBeacon === 'function';
const origin = getFullUrl('api');
const path = '/usage-logging/v1/log/hublytics-multi/no-auth';

const reportNetworkError = err => reportError(err, {
  fingerprint: ['usage-tracker-js', 'network']
});

const getDebug = () => enviro.debug(debugKey);

const isLocal = !enviro.deployed();
const isQa = enviro.getShort() === 'qa';
const trackingClient = createClient({
  clientName: 'app',
  getDebug,
  getEmail: () => userInfo().then(({
    user
  }) => user.email),
  getHubId: () => userInfo().then(({
    portal
  }) => portal.portal_id),
  getHstc: () => cookieHelper.get(hstcKey) || '',
  getLang: () => I18n.Info.then(getLang),
  getTempStorage: tempStorage.get,
  setTempStorage: tempStorage.set,
  logError: err => {
    if (isLocal || isQa || getDebug()) {
      console.error(err);
    }
  },
  logWarning: warning => {
    if (isLocal || isQa || getDebug()) {
      console.warn(warning);
    }
  },
  reportError,
  send: ({
    events,
    query,
    isAuthed,
    isBeforeUnload
  }) => {
    const endpoint = `${origin}${path}?${query}`;
    const data = events;

    const sendXhr = () => {
      let promise;

      if (isAuthed) {
        promise = apiClient.post(endpoint, {
          data
        }).catch(reportNetworkError);
      } else {
        promise = noAuthApiClient.post(endpoint, {
          data
        }).catch(reportNetworkError);
      }

      if (promise && typeof promise.done === 'function') {
        promise.done();
      }
    };

    if (isBeforeUnload && hasBeaconSupport) {
      sendBeacon(endpoint, data, sendXhr);
    } else {
      sendXhr();
    }
  }
});
export default trackingClient;