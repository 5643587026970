import I18n from 'I18n';
// @ts-expect-error not yet converted
import createPublicI18nProvider from 'I18n/init/providers/createPublicI18nProvider'; // @ts-expect-error not yet converted

import BasicI18nSetup from 'I18n/init/setup/BasicI18nSetup';
import lang from 'i2l?query=sporks!../../lang/en.lyaml';
export const loadInAppPreviewI18n = auth => {
  return BasicI18nSetup.setup(Object.assign({}, auth, {
    lang
  }));
};
export const loadPublicI18n = (locale = 'en') => {
  let publicI18nProvider;

  if (locale) {
    publicI18nProvider = createPublicI18nProvider({
      manuallySetLocale: true
    });
    publicI18nProvider.setLocale({
      langEnabled: true,
      locale
    });
  } else {
    publicI18nProvider = createPublicI18nProvider();
  }

  return publicI18nProvider.register(lang);
};

const getKey = key => `videoPlayer.controls.${key}`;

export function setupTranslations() {
  // based on https://github.com/videojs/video.js/blob/main/lang/en.json
  return {
    'Audio Player': I18n.text(getKey('audioPlayer')),
    'Video Player': I18n.text(getKey('videoPlayer')),
    Play: I18n.text(getKey('play')),
    Pause: I18n.text(getKey('pause')),
    Replay: I18n.text(getKey('replay')),
    'Current Time': I18n.text(getKey('currentTime')),
    Duration: I18n.text(getKey('duration')),
    'Remaining Time': I18n.text(getKey('remainingTime')),
    Fullscreen: I18n.text(getKey('fullscreen')),
    'Non-Fullscreen': I18n.text(getKey('exitFullscreen')),
    Mute: I18n.text(getKey('mute')),
    Unmute: I18n.text(getKey('unmute')),
    'Volume Level': I18n.text(getKey('volumeLevel')),
    'Play Video': I18n.text(getKey('playVideo'))
  };
}