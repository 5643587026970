import { useCallback, useEffect } from 'react';
import { MessageTypes } from 'video-embed/constants';
import { debugLog } from 'video-embed/utils/debug';
import { isPlayerMessage } from 'video-embed/utils/embed';
import { PlayerStatus } from '../constants';
import { mergeQueryParams } from '../utils/url';

const MessageHandler = ({
  setRef,
  embedId,
  parentOrigin,
  video,
  history,
  onSetUtk,
  onSetPageMeta,
  onSetCurrentTime,
  onUpdateDisplayOptions,
  onUpdatePlayerStatus
}) => {
  const postMessageToParent = useCallback((messageType, payload = {}) => {
    let messageData = {
      embedId,
      type: messageType,
      payload
    };

    if (video) {
      messageData = Object.assign({}, messageData, {
        videoId: video.videoId,
        videoTitle: video.title,
        isEmbeddable: video.isEmbeddable
      });
    }

    window.parent.postMessage(messageData, parentOrigin);
  }, [embedId, video, parentOrigin]);
  const handlePostMessage = useCallback(e => {
    if (!isPlayerMessage(e)) {
      return;
    }

    const {
      data
    } = e;
    debugLog(`Player "${embedId}" received postMessage`, data);

    if (data.type === MessageTypes.SET_UTK) {
      const utk = data.payload ? JSON.parse(data.payload).utk : null;
      onSetUtk(utk);
    } else if (data.type === MessageTypes.SET_PAGE_META) {
      // we add these params to the iframe url for tracking (such that it won't reload), avoiding need to bloat initial urls
      // these params are useful in formatting timeline events such that can link to the parent page vs the player iframe
      const pageMeta = JSON.parse(data.payload);
      const updatedLocation = mergeQueryParams(history.location, {
        parentTitle: pageMeta.pageTitle,
        parentUrl: pageMeta.pageUrl
      });
      history.replace(updatedLocation);
      onSetPageMeta(pageMeta);
    } else if (data.type === MessageTypes.SET_CURRENT_TIME) {
      const currentTime = data.currentTime || data.payload;

      if (typeof currentTime === 'number') {
        onSetCurrentTime(currentTime);
      }
    } else if (data.type === MessageTypes.UPDATE_DISPLAY_OPTIONS && data.payload) {
      onUpdateDisplayOptions(JSON.parse(data.payload));
    } else if (data.type === MessageTypes.SET_PLAYER_STATUS && data.payload) {
      const payload = JSON.parse(data.payload);

      if (Object.values(PlayerStatus).includes(payload.status)) {
        onUpdatePlayerStatus(payload.status);
      }
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  useEffect(() => {
    debugLog(`Adding postMessage listener for embed ${embedId}`);
    window.addEventListener('message', handlePostMessage);
    postMessageToParent(MessageTypes.PLAYER_LOADED);
    return () => {
      window.removeEventListener('message', handlePostMessage);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (setRef) {
    setRef({
      postMessageToParent
    });
  }

  return null;
};

export default MessageHandler;