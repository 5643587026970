import videojs from 'videojs-mux-kit';
import PortalIdParser from 'PortalIdParser';
import I18n from 'I18n';
import { getMuxEnvKey } from './env';
import { setupTranslations } from './I18n';
import './errorPlugin';
import './playbackOptions';

const getBasePlayerConfig = ({
  videoId,
  title
}) => ({
  plugins: {
    mux: {
      disableCookies: true,
      data: {
        env_key: getMuxEnvKey(),
        player_init_time: Date.now(),
        player_name: 'video-player-ui',
        video_id: videoId,
        video_title: title,
        video_producer: PortalIdParser.get(),
        sub_property_id: PortalIdParser.get()
      }
    }
  },
  controlBar: {
    children: ['PlayToggle', 'ProgressControl', 'RemainingTimeDisplay', 'VolumePanel', 'PlaybackPopover', 'FullscreenToggle'],
    AudioTrackButton: true,
    VolumePanel: {
      inline: false
    }
  },
  fill: true,
  timelineHoverPreviews: true,
  userActions: {
    doubleClick: false
  }
});

export default function createPlayer(idOrElement, {
  locale = 'en',
  loop = false,
  hiddenControls = false,
  muted = false,
  hidePosterImage = false,
  posterUrl,
  captionsTrackId
}, video) {
  posterUrl = posterUrl || video.posterUrl;
  const tracks = [];

  if (captionsTrackId) {
    tracks.push({
      src: `https://stream.mux.com/${video.primaryPlaybackId}/text/${captionsTrackId}.vtt`,
      kind: 'captions',
      label: I18n.text('videoPlayer.captionLanguages.en')
    });
  }

  videojs.addLanguage(locale, setupTranslations());
  const fileUrlParts = video.fileUrl.split('.');
  const fileExtension = fileUrlParts[fileUrlParts.length - 1] || '';
  const source = video.primaryPlaybackId ? {
    type: 'video/mux',
    src: video.primaryPlaybackId
  } : {
    type: `video/${fileExtension}`,
    src: video.fileUrl
  };
  return videojs(idOrElement, Object.assign({}, getBasePlayerConfig(video), {
    sources: [source],
    preload: 'none',
    poster: hidePosterImage ? undefined : posterUrl,
    tracks,
    loop,
    muted,
    controls: !hiddenControls,
    language: locale
  }));
}