'use es6';

import { isIframed } from 'video-embed/utils/env';
import startApp from './start';
window._hsq = window._hsq || [];

if (isIframed()) {
  document.body.classList.add('player-iframed');
} else {
  document.body.classList.add('player-standalone');
}

if (window.location.search.includes('maxHeight=')) {
  document.body.classList.add('with-max-height');
}

window._app = startApp();