import { fetchVideo } from '../utils/api';
import { FETCH_VIDEO_SUCCESS, FETCH_VIDEO_FAILED, FETCH_VIDEO_ATTEMPTED } from './ActionTypes';
export const loadVideo = (videoId, params) => dispatch => {
  const parentDomain = params.parentOrigin ? params.parentOrigin.replace(/^https?:\/\//, '') : null;
  dispatch({
    type: FETCH_VIDEO_ATTEMPTED
  });
  return fetchVideo(videoId, {
    parentDomain,
    pageId: params.pageId
  }).then(({
    video,
    parentDomain: domainInfo
  }) => {
    dispatch({
      type: FETCH_VIDEO_SUCCESS,
      video,
      domainInfo
    });
    return video;
  }).catch(error => {
    dispatch({
      type: FETCH_VIDEO_FAILED,
      error
    });
    throw error;
  });
};