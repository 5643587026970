import { isFragment } from 'react-is';
import devLogger from 'react-utils/devLogger';
export const warnIfFragment = (node, componentName, propName = 'children') => {
  // @ts-expect-error TODO: add webpack-env dep where we pull process types
  if (process.env.NODE_ENV !== 'production' && isFragment(node)) {
    devLogger.warn({
      message: `${componentName}: ${propName} cannot be a React.Fragment. Please refactor to use a DOM node.`,
      key: `${componentName}: fragment not supported`
    });
  }
};