import enviro from 'enviro';
import { debugLog } from 'video-embed/utils/debug';
import { loadScript } from 'video-embed/utils/dom';
export const MODAL_FORM_WRAPPER_CLASSNAME = 'video-form-wrapper';
export const SUBMIT_BUTTON_SELECTOR = '.hs-form__actions__submit';
export const LIGHT_COLOR_VALUE_THRESHOLD = 155;
export function loadFormsJs() {
  const formsJsDomain = enviro.isQa() ? 'hsformsqa.net' : 'hsforms.net';
  const formsJsUrl = `//js.${formsJsDomain}/forms/new-embed-script.js`;
  debugLog(`Loading forms JS at ${formsJsUrl}`);
  return loadScript(formsJsUrl);
}
/* eslint-disable no-bitwise */

export function getColorValue(hexColor) {
  // borrowed from https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
  const rgb = parseInt(hexColor.substring(1), 16);
  const r = rgb >> 16 & 0xff; // extract red

  const g = rgb >> 8 & 0xff; // extract green

  const b = rgb >> 0 & 0xff; // extract blue

  return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
}