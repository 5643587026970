'use es6';

import I18n from 'I18n';
export function initAppTranslations(translations) {
  return Promise.all(translations).then(result => {
    try {
      const newrelic = window.newrelic;
      window.performance.mark('mark_i18n_load_successful');
      window.performance.measure('measure_i18n_load_time', undefined, 'mark_i18n_load_successful');
      const i18nDuration = window.performance.getEntriesByName('measure_i18n_load_time')[0].duration;

      if (i18nDuration && newrelic) {
        newrelic.setCustomAttribute('i18nTime', i18nDuration);
      }

      const i18nLangLocale = I18n.locale !== 'en-us' && !I18n.langEnabled && !I18n.publicPage ? 'en-us' : I18n.locale;

      if (newrelic) {
        newrelic.setCustomAttribute('i18nLangLocale', i18nLangLocale);
        newrelic.setCustomAttribute('i18nDateNumFormat', I18n.locale);
        newrelic.setCustomAttribute('isNewI18nLoader', true);
      }
    } catch (e) {
      return result;
    }

    return result;
  });
}