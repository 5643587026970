export const EMBED_PLACEHOLDER_SELECTOR = '[data-hsv-embed-id]:not([data-hsv-status])';
export const DEFAULT_ASPECT_RATIO = 9 / 16;
export const PLAYER_EVENT_TYPE = 'HS:PlayerMessage';
export let MessageTypes;

(function (MessageTypes) {
  MessageTypes["SET_UTK"] = "SET_UTK";
  MessageTypes["SET_PAGE_META"] = "SET_PAGE_META";
  MessageTypes["TRACKED_PLAY"] = "TRACKED_PLAY";
  MessageTypes["SET_CURRENT_TIME"] = "SET_CURRENT_TIME";
  MessageTypes["UPDATE_DISPLAY_OPTIONS"] = "UPDATE_DISPLAY_OPTIONS";
  MessageTypes["SET_PLAYER_STATUS"] = "SET_PLAYER_STATUS";
  MessageTypes["PLAYER_LOADED"] = "PLAYER_LOADED";
  MessageTypes["PLAYER_PLAY"] = "PLAYER_PLAY";
  MessageTypes["PLAYER_PAUSE"] = "PLAYER_PAUSE";
  MessageTypes["PLAYER_ENDED"] = "PLAYER_ENDED";
})(MessageTypes || (MessageTypes = {}));

export let ConversionAssetTypes;

(function (ConversionAssetTypes) {
  ConversionAssetTypes["FORM"] = "FORM";
  ConversionAssetTypes["CTA"] = "CTA";
})(ConversionAssetTypes || (ConversionAssetTypes = {}));

export let ConversionAssetPosition;

(function (ConversionAssetPosition) {
  ConversionAssetPosition["PRE"] = "PRE";
  ConversionAssetPosition["POST"] = "POST";
  ConversionAssetPosition["CUSTOM"] = "CUSTOM";
})(ConversionAssetPosition || (ConversionAssetPosition = {}));

export const PLAYER_IFRAME_PASSTHRU_PARAMS = ['region', 'captionsTrackId', 'posterUrl', 'playButtonColor', 'play_button_color', 'playButtonShape', 'play_button_shape', 'preview', 'richTextPreview', 'playerType', 'autoplay', 'loop', 'muted', 'hiddenControls', 'hidden_controls', 'hostApp', 'renderContext', 'parentUrl', 'maxHeight'];
export const CONFIRM_PLAYER_RENDERED_DELAY_MS = 1000;