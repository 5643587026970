'use es6'; //I18n Configuration

import createStandardI18nProvider from 'I18n/init/providers/createStandardI18nProvider';
import registerAllData from 'I18n/init/register/sync/registerAllData';
import { initAppTranslations } from 'I18n/init/setup/AppInit'; // Import extra data

import 'I18n/init/data/currency';
import 'I18n/init/data/baseLocales';
import 'I18n/init/data/publicLocales';
export default {
  provider: null,
  setup: function setup({
    lang,
    user,
    portal
  }) {
    this.provider = createStandardI18nProvider();
    const langAvailable = initAppTranslations([this.provider.register(lang), registerAllData(this.provider)]);
    this.provider.setLocale({
      locale: user.locale,
      langEnabled: user.lang_enabled || user.langEnabled,
      timezone: portal.timezone
    });
    return langAvailable;
  }
};