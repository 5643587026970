import { MessageTypes, EMBED_PLACEHOLDER_SELECTOR } from '../constants';
import { getPortalId, isCmsEditorPreview } from './cms';
import { debugLog } from './debug';
import { getEmbedPlaceholderEl } from './dom';
import { reportMessage } from './errors';
import { getEmbedIframeDomain } from './env';
import { PlayerStatus, PlayerTypes } from '../types';
import { buildIframeQueryParams, getEmbedIframeUrl } from './url';
import EmbedPlayer from './EmbedPlayer';
export function isPlayerMessage(event, expectedSource) {
  if (event && event.data && typeof event.data === 'object' && Object.keys(MessageTypes).includes(event.data.type)) {
    if (expectedSource && event.origin && event.origin !== expectedSource) {
      debugLog('isPlayerMessage saw unexpected origin', event);
    }

    return true;
  }

  return false;
}
export function renderEmbeddedPlayer(id, options, {
  placeholderEl
} = {}) {
  if (!placeholderEl && options.embedId) {
    placeholderEl = getEmbedPlaceholderEl(id, true);

    if (placeholderEl) {
      options.renderContext = 'embed-id-selector';
    } else {
      debugLog(`Failed to find for embedId: ${options.embedId} for player: ${id}`);
    }
  }

  if (!placeholderEl) {
    placeholderEl = document.querySelector(EMBED_PLACEHOLDER_SELECTOR);
    options.renderContext = 'generic-selector';
  }

  const portalId = options.portalId || getPortalId();

  if (!portalId || !options.id) {
    reportMessage('Required param `portalId` or video `id` missing');
  }

  if (isCmsEditorPreview()) {
    options.playerType = PlayerTypes.EDITOR_PREVIEW;
  }

  const iframeParams = buildIframeQueryParams(options);
  const iframeSrc = getEmbedIframeUrl(portalId, options.id, iframeParams, id);
  debugLog(`Rendering iframe for player ${id}`, options, placeholderEl);
  const embedPlayer = new EmbedPlayer(id, options, iframeSrc, placeholderEl);
  embedPlayer.injectPlayer();
  return embedPlayer;
}
export function updatePlayerOptions(player, options) {
  const portalId = options.portalId || getPortalId();
  const iframeParams = buildIframeQueryParams(options);
  const iframeSrc = getEmbedIframeUrl(portalId, options.id, iframeParams, player.id);
  debugLog(`Updating src of existing iframe for player ${player.id}`, options);
  player.options = options;
  player.iframeEl.src = iframeSrc;
  return player;
}
export function postMessageToPlayers(players, type, payload, queueIfNotReady = true) {
  Object.values(players).forEach(player => {
    player.postMessageToPlayer(type, payload, queueIfNotReady);
  });
}
export function setupPlayerMessageListener(players) {
  window.addEventListener('message', e => {
    if (isPlayerMessage(e, getEmbedIframeDomain())) {
      const {
        data
      } = e;
      const {
        type,
        embedId,
        payload = {}
      } = data;
      const player = players[embedId];

      if (!player) {
        console.warn(`Could not find embedId ${embedId}`, data);
        return;
      }

      player.onMessageReceived(type, payload);

      if (type === MessageTypes.PLAYER_PLAY && !(player.options.autoplay && payload.isInitial)) {
        const otherPlayingPlayers = Object.values(players).filter(p => {
          return p !== player && p.status === PlayerStatus.PLAYING;
        });

        if (otherPlayingPlayers.length) {
          debugLog(`Pausing ${otherPlayingPlayers.length} other currently playing players`, otherPlayingPlayers);
        }

        otherPlayingPlayers.forEach(p => p.triggerPause());
      }
    }
  });
}