'use es6';

import { configError, genericError } from './common/messages';
import { defaults, omit, shallowCopy, resolveAsyncProperties } from './common/helpers';
import { trackerConfigSchema, trackerPropertiesSchema } from './schemas';

const getDefinition = ({
  events,
  logError,
  onError,
  dictionaryLookup
}, eventKey, eventProperties) => {
  try {
    return dictionaryLookup(events, eventKey, eventProperties);
  } catch (err) {
    let stringifiedProperties = 'error parsing properties';

    try {
      stringifiedProperties = JSON.stringify(eventProperties);
    } catch (err2) {
      /* NOOP */
    }

    onError(err, {
      extra: {
        eventProperties: stringifiedProperties
      }
    });
    logError(err);
    return null;
  }
};

const getIdentifiers = ({
  createIdentifiers,
  allowUnauthed,
  isExternalHost,
  logError,
  onError
}, {
  email,
  userId,
  hubId,
  hstc,
  device_id: deviceId,
  hasDeviceIdOverride
}) => {
  try {
    return createIdentifiers({
      email,
      userId,
      hubId,
      hstc,
      deviceId: hasDeviceIdOverride ? deviceId : null
    }, {
      allowUnauthed,
      isExternalHost
    });
  } catch (err) {
    onError(err);
    logError(err);
    return null;
  }
};

const createEvent = ({
  createEventPayload,
  logError,
  onError
}, definition, identifiers, eventProperties) => {
  try {
    return createEventPayload(definition, eventProperties, identifiers);
  } catch (err) {
    onError(err);
    logError(err);
    return null;
  }
};

const onScheduled = (config, eventKey, event, expectedPropertiesKeys, receivedPropertiesKeys) => {
  const debug = typeof config.debug === 'function' ? config.debug() : config.debug;

  if (typeof config.onScheduled === 'function') {
    config.onScheduled(eventKey);
  }

  try {
    if (debug) {
      config.logDebug(eventKey, event);
    }

    config.logUnexpectedProperties(eventKey, expectedPropertiesKeys, receivedPropertiesKeys);
  } catch (err) {
    /* NoOp */
  }
};

const trackEvent = (config, eventKey, mergedProperties, eventPropertiesKeys) => {
  const definition = getDefinition(config, eventKey, mergedProperties);
  if (!definition) return false;
  const identifiers = getIdentifiers(config, mergedProperties);
  if (!identifiers) return false;
  delete mergedProperties.hasDeviceIdOverride;
  const event = createEvent(config, definition, identifiers, mergedProperties);
  if (!event) return false;
  config.scheduleEvent(config, identifiers, eventKey, event);
  onScheduled(config, eventKey, event, Object.keys(definition.properties), eventPropertiesKeys);
  return true;
};

const parseConfig = (trackerDependencies, config) => {
  if (!config || typeof config !== 'object') {
    throw configError(`Invalid argument. The "createTracker" function requires to be passed a config argument of type "object". Received type "${typeof config}".`);
  }
  /*
   *  Apply defualts to schemas.
   *  Defaults such as onError are not known until runtime.
   */


  const trackerConfigSchemaWithDefaults = trackerConfigSchema.mutate(schema => {
    schema.debug.default = trackerDependencies.getDebug;
    schema.onError.default = trackerDependencies.reportError;
    return schema;
  });
  const trackerPropertiesSchemaWithDefaults = trackerPropertiesSchema.mutate(schema => {
    schema.email.default = trackerDependencies.getEmail;
    schema.hubId.default = trackerDependencies.getHubId;
    schema.hstc.default = trackerDependencies.getHstc;
    schema.lang.default = trackerDependencies.getLang;
    return schema;
  });
  /*
   *  Normalize & validate tracker config + properties.
   */

  const parsedConfig = trackerConfigSchemaWithDefaults.normalize(config);
  trackerConfigSchemaWithDefaults.validate(parsedConfig, '"createTracker"');
  const parsedTrackerProperties = trackerPropertiesSchemaWithDefaults.normalize(parsedConfig.properties);
  trackerPropertiesSchemaWithDefaults.validate(parsedTrackerProperties, '"createTracker"');
  const dictionary = trackerDependencies.createDictionary(parsedConfig.events, '"createTracker"');
  return Object.assign({
    events: dictionary,
    properties: parsedTrackerProperties
  }, omit(parsedConfig, ['events', 'properties']), {}, trackerDependencies);
};

export const createLockedTracker = (trackerDependencies, config = {}) => {
  const parsedConfig = parseConfig(trackerDependencies, config);
  const lastKnownPropertyCache = {};
  let properties = Object.assign({}, parsedConfig.properties);
  let propertiesAreDirty = true;
  /*
   *  Syncs lastKnownEventProperties each
   *  time an event is tracked with eventProperties.
   */

  const lastKnownPropertyCacheSync = eventProperties => {
    if (parsedConfig.lastKnownEventProperties && parsedConfig.lastKnownEventProperties.length) {
      parsedConfig.lastKnownEventProperties.forEach(key => {
        let value = eventProperties[key];

        if (value !== undefined) {
          lastKnownPropertyCache[key] = value;
        } else {
          value = lastKnownPropertyCache[key];
        }

        if (value && value !== properties[key]) {
          properties[key] = value;
        }
      });
    }
  };

  return {
    clone: (overrides = {}) => {
      if (!overrides || typeof overrides !== 'object') {
        throw genericError(`Invalid argument. The "clone" method requires to be passed a valid tracker config of type "object". Received type "${typeof overrides}".`);
      }

      const mergedConfig = omit(defaults(overrides, parsedConfig), Object.keys(trackerDependencies));
      const supportedProperties = omit(properties, trackerPropertiesSchema.getKeys(), false);
      const arbitraryProperties = omit(properties, Object.keys(supportedProperties));
      mergedConfig.properties = defaults(overrides.properties || {}, supportedProperties);

      if (mergedConfig.preserveTrackerProperties) {
        mergedConfig.properties = defaults(mergedConfig.properties, arbitraryProperties);
      }

      if (mergedConfig.preserveTrackerEvents) {
        mergedConfig.events = defaults(mergedConfig.events || {}, parsedConfig.events);
      }

      return createLockedTracker(trackerDependencies, mergedConfig);
    },
    getConfig: () => {
      const debug = typeof parsedConfig.debug === 'function' ? parsedConfig.debug() : parsedConfig.debug;

      if (debug) {
        return Object.assign({}, omit(parsedConfig, ['properties']), {
          properties
        });
      }

      throw genericError(`Invalid call. This method should only be used when 'debug: true'. Please do not use this in production.`);
    },
    setProperties: (newProperties = {}) => {
      if (!newProperties || typeof newProperties !== 'object') {
        throw genericError(`Invalid argument. The "setProperties" method requires to be passed a properties argument of type "object". Received type "${typeof newProperties}".`);
      }

      Object.keys(newProperties).forEach(key => {
        const value = newProperties[key];
        properties[key] = value;
      });
      propertiesAreDirty = true;
    },
    track: (eventKey, eventProperties = {}) => {
      if (!eventKey || typeof eventKey !== 'string') {
        throw genericError(`Invalid argument. The "track" method requires to be passed an eventKey of type "string". Received type "${typeof eventKey}".`);
      }

      if (!eventProperties || typeof eventProperties !== 'object') {
        throw genericError(`Invalid argument. The "track" method requires the 2nd arg to be passed eventProperties of type "object". Received type "${typeof eventProperties}".`);
      }

      const eventPropertiesKeys = Object.keys(eventProperties);
      const eventPropertiesCopy = Object.assign({}, eventProperties, {
        eventKey
      });
      let arbitraryProperties = defaults(eventPropertiesCopy, shallowCopy(properties));
      const metaProperties = shallowCopy(parsedConfig.getMetaProperties({
        deviceIdOverride: arbitraryProperties.deviceId
      }));
      let mergedProperties = defaults(arbitraryProperties, metaProperties);
      lastKnownPropertyCacheSync(mergedProperties);

      if (propertiesAreDirty) {
        resolveAsyncProperties(properties, parsedConfig.onError, resolved => {
          properties = defaults(resolved, properties);
          propertiesAreDirty = false;
          arbitraryProperties = defaults(eventPropertiesCopy, shallowCopy(properties));
          mergedProperties = defaults(arbitraryProperties, metaProperties);
          trackEvent(parsedConfig, eventKey, mergedProperties, eventPropertiesKeys);
        });
      } else {
        trackEvent(parsedConfig, eventKey, mergedProperties, eventPropertiesKeys);
      }
    }
  };
};